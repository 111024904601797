export default {

    install(Vue) {
       
      
        Vue.prototype.$getDateFromUnixTimestamp = function(unixTimestamp)
        {
          var date = new Date( unixTimestamp.substring(0,10) * 1000);

          var year = date.getFullYear()
          var month = date.getMonth()+1
          var day = date.getDate()

          if(month.toString().length == 1) month = "0" + month;
          if(day.toString().length == 1) day = "0" + day;
    
          return year + "." + month + "." + day ;
      
        },
        Vue.prototype.$getHourMinutesFromMiliseconds = function (miliseconds){

            const dateObject = new Date(miliseconds)
            var min = dateObject.getMinutes()
          
            if(min.toString().length == 1) min = "0" + min;
            
            return  dateObject.getHours() +"시 "+ min +"분"
        },
        Vue.prototype.$getRoundRemainTime = function (endDate){

            //종료시간을 받아서 현재시간과의 차이를 초단위로 리턴한다.
            var nowDate = new Date();
            const msec =  endDate - nowDate
            
            if(msec < 0)
            {
              return 0
            }
            else{
              return (msec/1000)
            }
        },
        Vue.prototype.$getElectionDayString = function (electionStartDate,timezonediff){

          //October 8, 2022 beginning at 10:00 PM GMT+9

          //console.log(electionStartDate)
          var tempDate =  new Date(electionStartDate)
          tempDate.setHours(tempDate.getHours()+ timezonediff)

          var year = tempDate.getUTCFullYear()
          //var month = tempDate.getUTCMonth()
          var day = tempDate.getUTCDate()
          var hour = tempDate.getHours()
          

          var monthName =  tempDate.toLocaleString('en-US', {
              month: 'long',
          });
    
          return monthName + " " + day + ', ' + year + ' beginning at ' + hour + ':00';
        },
        Vue.prototype.$getElectionDayStringWithMinusHours = function (electionStartDate,timezonediff,minusHours){
 
          var tempDate =  new Date(electionStartDate)
          tempDate.setHours(tempDate.getHours()-minusHours+ timezonediff)

          var year = tempDate.getUTCFullYear()
          var day = tempDate.getUTCDate()
          var hour = tempDate.getHours()

          var monthName =  tempDate.toLocaleString('en-US', {
              month: 'long',
          });
    
          return monthName + " " + day + ', ' + year + ' beginning at ' + hour + ':00';
        },
        Vue.prototype.$getMMDDYYYYString = function (dateTime,timezonediff){

           
          var tempDate =  new Date(dateTime)
          tempDate.setHours(tempDate.getHours()+ timezonediff)

          var year = tempDate.getUTCFullYear()
          //var month = tempDate.getUTCMonth()
          var day = tempDate.getUTCDate()
          var hour = tempDate.getHours()

          var monthName =  tempDate.toLocaleString('en-US', {
              month: 'long',
          });
    
          return monthName + " " + day + ', ' + year  ;
        },
        Vue.prototype.$getElectionPreDayString = function (electionStartDate,timezonediff){

          //October 8, 2022 beginning at 10:00 PM GMT+9

          var tempDate =  new Date(electionStartDate)
          tempDate.setDate(tempDate.getDate() -1);
          tempDate.setHours(tempDate.getHours()+ timezonediff)

          var year = tempDate.getUTCFullYear()
          var day = tempDate.getUTCDate()
          var hour = tempDate.getHours() 
          

          var monthName =  tempDate.toLocaleString('en-US', {
              month: 'long',
          });
    
          return monthName + " " + day + ', ' + year + ' beginning at ' + hour + ':00';
        },
        Vue.prototype.$getElectionDayDisplay = function (electionStartDate,timezonediff,minusHours){

          //April 9, 2022
          var tempDate =  new Date(electionStartDate)
          tempDate.setHours(tempDate.getHours()-minusHours + timezonediff)

          var year = tempDate.getUTCFullYear()
          var day = tempDate.getUTCDate()
          var hour = tempDate.getHours() 

          var monthName =  tempDate.toLocaleString('en-US', {
              month: 'long',
          });
    
          return monthName + " " + day + ', ' + year;
        },
        Vue.prototype.$getElectionMonthAndDayOnly = function (electionStartDate,timezonediff){

          var tempDate =  new Date(electionStartDate)
          tempDate.setHours(tempDate.getHours()+ timezonediff)
          var day = tempDate.getUTCDate()

          var monthName =  tempDate.toLocaleString('en-US', {
              month: 'short',
          });
    
          return monthName + " " + day
        },
        Vue.prototype.$getSecToMinAndSecond = function (msec){

            var mm = Math.floor(msec / 60);
            msec -= mm * 60;
            var ss = Math.floor(msec);
            
            if(mm.toString().length == 1) mm = "0" + mm.toString();
            if(ss.toString().length == 1) ss = "0" + ss.toString();
      
           return  mm +":" + ss
        },
        Vue.prototype.$getSecToHourAndMinAndSecond = function (msec){

            var hh = Math.floor(msec / 60 / 60);
            msec -= hh * 60 * 60;
            var mm = Math.floor(msec / 60);
            msec -= mm * 60;
            var ss = Math.floor(msec);
         
            if(mm.toString().length == 1) mm = "0" + mm.toString();
            if(ss.toString().length == 1) ss = "0" + ss.toString();
      
          return hh +":" + mm +":" + ss ;
    
        },
        Vue.prototype.$getNowYYYMMDD = function (){

          var nowDate = new Date()
          
          var year = nowDate.getUTCFullYear()
          var month = nowDate.getUTCMonth()+1
          var day = nowDate.getUTCDate()

          if(month.toString().length == 1) month = "0" + month;
          if(day.toString().length == 1) day = "0" + day;
    
          return year + "-" + month + "-" + day ;
  
        },
        Vue.prototype.$isConsensusOk = function (total,majority,voteSelf){

          var tempConsensus = false
          if(total == 6 || total == 5)
          {
            ttempConsensus = majority >= 4
          }
          else if(total== 4 || total == 3)
          {
            tempConsensus =majority >= 3
          }
          else if(total== 2)
          {
            tempConsensus = majority >= 2
          }
          else if(total== 1)
          {
            tempConsensus = majority == 1
          }
          else
          {
              tempConsensus = false
          }

          if(tempConsensus)
          {
            if(voteSelf == false) {
              return false
              
            }
            return true
          }
          else
          {
            return false
            
          }
      }
    }
}