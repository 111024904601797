<template>

  <v-app id="inspire">

    <v-app-bar
      app
      color="white"
      elevation="1"
      >
 
       <!-- <v-img max-height="35" max-width="35"
        src=" https://raw.githubusercontent.com/eoscafe/eos-airdrops/master/logos/eosio.png"></v-img> -->

           <v-img max-height="32" max-width="22"
        :src="require('@/assets/questico.png')"></v-img>

       <!-- <v-app-bar-title class="text-no-wrap d-none d-sm-flex" style="margin-left:5px;width:135px" ><strong>EOS Quest</strong> </v-app-bar-title> -->
       <v-app-bar-title class="d-none d-sm-flex ml-1"   ><strong>QUEST</strong> </v-app-bar-title>
 

      <v-tabs  
        centered
        class="sm-n9"
        color="grey darken-1"
        v-show="!isMobile"
      >
        <!-- <v-tab
          v-for="link in links"
          :key="link"
        >
          {{ link }}
        </v-tab> -->

        <v-tab @click="routerlinkHome">Home</v-tab>
        <v-tab @click="routerlinkTokenSend">Token</v-tab>
        <v-tab @click="routerlinkDaily">
          Daily
        </v-tab>

        <v-tab @click="routerlinkCalendar">
           <v-badge
            bordered
              color="orange"
              dot
              >
              Calendar
          </v-badge>
        </v-tab>
        <v-tab @click="routerlinkQuest">
         QUEST
        </v-tab>
        <v-tab @click="routerlinkDapp">Dapp</v-tab>
        <!-- <v-tab @click="routerlinkEden">Eden</v-tab>
        <v-tab @click="routerlinkBakje">BAKJE</v-tab> -->
        <v-tab @click="routerlinkAbout">About</v-tab>

      </v-tabs>

    

     <!-- <v-tooltip bottom >
      <template v-slot:activator="{ on, attrs }" >
          <v-btn tile text @click="loingAnchor"  v-bind="attrs"  
          v-on="on">
        <v-icon v-show="!isLogined">mdi-fingerprint </v-icon>
        <v-icon  v-show="isLogined">mdi-fingerprint-off </v-icon>
          <h3>{{loginAccountName}} </h3>
      </v-btn>
      </template>
      <span v-show="!isLogined">Login With Anchor</span>
      <span v-show="isLogined" style="color:red">Logout</span>
    </v-tooltip> -->
  

     <v-spacer></v-spacer>
     
     <v-tooltip bottom >
      <template v-slot:activator="{ on, attrs }" >
          <v-btn icon @click="loginAnchor"  v-bind="attrs" v-show="!isLogined"
          v-on="on">
        <v-icon>mdi-fingerprint </v-icon>
      </v-btn>
      </template>
      <span>Login With Anchor</span>
    </v-tooltip>
  
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
          <v-btn icon @click="logoutAnchor"  v-bind="attrs"  v-show="isLogined"
          v-on="on">
        <v-icon>mdi-fingerprint-off </v-icon>
      </v-btn>
      </template>
      <span >Logout</span>
    </v-tooltip>

   

<v-btn text  style="text-transform: none" v-show="isLogined" > <h3>{{loginAccountName}} </h3></v-btn>
     

      
      <AuthBtn/>

      <v-menu
        left
        bottom
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon  color="primary">mdi-menu-open</v-icon>
          </v-btn>
        </template>


        <v-list>
          <v-list-item>
            <v-list-item-title @click="routerlinkHome">Home</v-list-item-title>
          </v-list-item>
          <v-list-item>
             <v-list-item-title @click="routerlinkTokenSend">Token</v-list-item-title>
          </v-list-item>
          <v-list-item>
             <v-list-item-title @click="routerlinkDaily">Daily</v-list-item-title>
          </v-list-item>
          <v-list-item>
              <v-list-item-title @click="routerlinkCalendar">Calendar</v-list-item-title>
          </v-list-item>
          <v-list-item>
              <v-list-item-title @click="routerlinkQuest">Quest</v-list-item-title>
          </v-list-item>
          <v-list-item>
           <v-list-item-title @click="routerlinkDapp">dApp</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title @click="routerlinkAbout">About</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>


    </v-app-bar>
 

    <router-view/>
 

<template>
<v-footer
    padless
  >

  <v-col
  class="text-center"
  cols="12">

   Copyright © <strong>EOSQuest</strong> {{ new Date().getFullYear() }}.<br v-if="sm">

   <v-chip small  class="ml-1" @click="goTerm">Terms of Use</v-chip> <v-chip small @click="goPrivacy">Privacy</v-chip>

  </v-col>

    <!-- <v-card
     style="background-color:#F2F3F8"
     
      
      class="black--text text-center"
    >
      <v-card-text>
        <v-btn
          v-for="icon in icons"
          :key="icon"
          class="mx-4 black--text"
          icon
        >
          <v-icon size="24px">
            {{ icon }}
          </v-icon>
        </v-btn>
      </v-card-text>

      <v-card-text class="black--text pt-0">
        Phasellus feugiat arcu sapien, et iaculis ipsum elementum sit amet. Mauris cursus commodo interdum. Praesent ut risus eget metus luctus accumsan id ultrices nunc. Sed at orci sed massa consectetur dignissim a sit amet dui. Duis commodo vitae velit et faucibus. Morbi vehicula lacinia malesuada. Nulla placerat augue vel ipsum ultrices, cursus iaculis dui sollicitudin. Vestibulum eu ipsum vel diam elementum tempor vel ut orci. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text class="black--text">
       Copyright  <strong>EOS Quest</strong> {{ new Date().getFullYear() }}. All rights reserved.
      </v-card-text>
    </v-card> -->
  </v-footer>
</template>


  </v-app>
 
 
 
   
</template>




  
<script>

//import { eosNodeEndpoint } from './components/variables.js'

import { EDEN_CONTRACT_CODE,CHAIN_ID,NODE_URL } from "./config";

import AnchorLink from 'anchor-link'
import AnchorLinkBrowserTransport from 'anchor-link-browser-transport'

import AuthBtn from '@/components/AuthBtn'
// import AuthBtn from './components/AuthBtn.vue'

const devIdentify = "eosquest.io"

const transport = new AnchorLinkBrowserTransport()
const link = new AnchorLink({
    transport,
    chains: [
        {
          chainId: CHAIN_ID,//메인넷
          nodeUrl: NODE_URL,//메인넷
        }
    ]
})
 
export default {
  components: { AuthBtn },
  name: 'App',
    data () {
    return {
      isMobile: false,
      settingMenu:["Setting","Profile"],
      icons: [
        'mdi-facebook',
        'mdi-twitter',
        'mdi-linkedin',
        'mdi-instagram',
      ],
       links: [
        'Dashboard',
        'Token',
        'About',
      ],
      isLogined : false,
      loginAccountName:'Sign in',
      
    }
  },
  // //getter 처럼 다른 타입으로 변환(converter 역할)
  // computed: {
    
  // },
  //변경되었을때 감시하고 있다가 값을 업데이트 한다.
  watch: {
      isLogined:function(newVal,oldVal)
      {
        this.loginAccountName = newVal ? this.$store.state.mainAnchorSession.auth.actor:"Sign in"
        
      }
  },  
   
  methods:{
   goTerm()
   {
      this.$router.push('/Terms') 
   },
   goPrivacy()
   {
      this.$router.push('/Privacy') 
   },

   routerlinkTokenSend()
   {
      this.$router.push('/tokensend') 
   },
    routerlinkHome()
   {
      this.$router.push('/') 
   },
     routerlinkAbout()
   {
      this.$router.push('/about') 
   },
   routerlinkDapp()
   {
      this.$router.push('/dapp') 
   },
   routerlinkEden()
   {
      this.$router.push('/eden') 
   },
   routerlinkQuest()
   {
      this.$router.push('/quest') 
   },
   routerlinkBakje()
   {
      this.$router.push('/bakje') 
   },
   routerlinkDaily()
   {
      this.$router.push('/daily') 
   },
   routerlinkCalendar()
   {
      this.$router.push('/calendar') 
   },
   
   async loginAnchor()
   {
 
     const identity = await link.login(devIdentify)

     const {session} = identity
 
     this.$store.state.mainAnchorSession  = session
     this.$store.state.mainAnchorLoginName = session.auth.actor
     this.isLogined = true
     
     this.checkIfEdenMember();
     
   },
   async logoutAnchor()
   {
     await link.clearSessions(devIdentify)
     this.$store.state.mainAnchorSession = null
     this.$store.state.mainAnchorLoginName = ''
     this.isLogined = false
     this.$store.state.isEdenMember = false;

   },
   onResize () {
        this.isMobile = window.innerWidth < 600
      },

   restoreAnchorSesstion(){
      
      link.restoreSession(devIdentify).then((resultSession) => {
       
         this.$store.state.mainAnchorSession  = resultSession
         this.$store.state.mainAnchorLoginName = resultSession.auth.actor
         this.isLogined = true

         this.checkIfEdenMember();
 
      })
    },

    checkIfEdenMember()
    {

      if(this.$store.state.mainAnchorLoginName == '') return;
      
      (async () => {

          //정글넷
          // var sendJson = {
          //     "code": "hongtester12",
          //     "table": "member",
          //     "scope": "0",
          //     "lower_bound": this.$store.state.mainAnchorLoginName,
          //     "limit": "1"
          // };

           var sendJson = {
              "code": EDEN_CONTRACT_CODE,
              "table": "member",
              "scope": "0",
              "lower_bound": this.$store.state.mainAnchorLoginName,
              "limit": "1"
          };

        var result =  await this.$eosjsRPC.get_table_rows(sendJson)

        if( result.rows.length == 1)
        {
           if(result.rows[0][1].name != '' && result.rows[0][1].account == this.$store.state.mainAnchorLoginName)
           {
              this.$store.state.isEdenMember = true;
              console.log("eden member");
           }
           else{
             console.log("not eden member");
           }
        }
        else{
          console.log("not eden member");
        }
    
      })();

    }

 },
 beforeDestroy () 
 {
    if (typeof window === 'undefined') return

    window.removeEventListener('resize', this.onResize, { passive: true })
 },
 mounted () {
 
    this.onResize()
    window.addEventListener('resize', this.onResize, { passive: true })
  
    this.restoreAnchorSesstion();
    this.$vuetify.goTo(0)
 },

    
};
</script>


 
