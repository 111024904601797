import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    mainAnchorSession:null,
    mainAnchorLoginName:'',
    isEdenMember:false,
    dailyTokenVIODate:'',
    dailyTokenVIOBalance:'',
    isFirebaseAdmin:false,
    firebaseLoginUserInfo:null
  },
  getters:{
    isAnchorLogined : state => {
      return state.mainAnchorLoginName == '' ? false:true
    },
   
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
