<template>

      <v-sheet v-if="isLoadingShow"    style="margin:17px 0px 0px 0px;">
      <v-skeleton-loader 
         
        type=" image, article, actions"
         v-for="i in 4" :key="i"
      >
      </v-skeleton-loader>
      </v-sheet>
       

     <v-sheet
        min-height="70vh"
        rounded="lg"
        color="#f5f5f5"
        v-else>

      <v-card
       
              class="mx-auto rounded-xl"
              color="white"
              style="margin:17px 10px 10px 10px;"   
              v-for="(item,i) in articleItems" :key="i">


              <v-container fill-height>
                     <v-img   
                        class="rounded-xl" 
                        min-height="200" 
                        max-height="300" 
                         v-if="item.data().imgLink != ''"
                        :src="item.data().imgLink == '' ? '' : item.data().imgLink"></v-img>
                  </v-container>

                   <v-card-title>
                      <span class="text-h5 font-weight-bold">{{item.data().title}}</span>
                  </v-card-title>

                  <v-card-text class="text-h6 font-weight-light">
                    <div v-html="item.data().body"></div>
                  </v-card-text>


                <v-card-actions>
              <v-list-item class="grow">

                <v-row
                  justify="start"
                >

              <v-badge
                  :content="item.data().linkClickCount == null ? 0 : item.data().linkClickCount "
                  :value="item.data().linkClickCount == null ? 0 : item.data().linkClickCount"
                  color="grey"
                  overlap
              >
                <v-chip color="blue lighten-5" text  @click="openLink(item.data().link,item.id)" >Read More</v-chip>
              </v-badge>
                  
                  <v-chip @click="deleteArticle(item.id)" v-show="isShow" 
                          color="red"
                          text-color="white"
                          class="ml-2" >Delete</v-chip>

                            <v-chip @click="commentArticle(item.id)" v-show="isShow" 
                          color="green"
                          text-color="white"
                          class="ml-2" >comment</v-chip>
                </v-row>
              </v-list-item>
            </v-card-actions>
               
             
       </v-card>
            
     </v-sheet>

   

   
</template>



<script>



import { collection, getDocs,
         onSnapshot,
         query, orderBy, limit,
         doc,updateDoc,deleteDoc,
         addDoc , serverTimestamp,
         increment
        } from "firebase/firestore"; 

import { db } from '@/plugins/firebase';


export default {
    data() {

        return {
            unsubscribe:null,
            snapshots: [],
            menu:false,
            willUpdateTitle:'',
            willUpdateBody:'',
            willUpdateLink:'',
            willUpdateImageLink:'',
            isShow:false,
            isLoadingShow :true
        }
    },
   watch: {

  '$store.state.isFirebaseAdmin': function() {

    if(this.$store.state.isFirebaseAdmin)
    {
      //true
      this.isShow = true;
    }
    else{
      //false
      this.isShow = false;

    }

   }
  },
     computed :{

        articleItems(){
            // return this.snapshots.map(s=>s.data());
            return this.snapshots;
        },
       
    },

    methods: {

      //  getImageURL(item)
      //   {
      //     if(item.data().imgLink != '') return item.data().imgLink;

      //     console.log(item);

      //     if(item.data().youtubeID == 'undefined') return '';

      //     console.log('https://img.youtube.com/vi/'+ item.data().youtubeID +'/0.jpg');

      //     return 'https://img.youtube.com/vi/'+ item.data().youtubeID +'/0.jpg';

          
      //   },
      //   isShowImg(item)
      //   {
      //     if(item.data().imgLink != '') return true;

      //     if(item.data().youtubeID == 'undefined') return  false;

      //     if(item.data().youtubeID == '')
      //     {
      //        //console.log(youtubeID);
      //       return false;
      //     }
      //     else{
      //       //console.log(youtubeID);
      //       return true;

      //     }
 
          
      //   },

      
        openMenu(linkURL)
        {
            if(linkURL=="") return;
            window.open(linkURL, '_blank');
        },
       
        async openLink(linkURL,docID)
        {
            if(linkURL=="") return;
           
            window.open(linkURL, '_blank');

            var ref = doc(db,'articles',docID);

            await updateDoc(ref, {
               linkClickCount: increment(1)
            });

        },

        subscribe()
        {
            const q = query(collection(db, "articles"), orderBy("regdate", "desc"), limit(15));
            this.unsubscribe = onSnapshot(q, (querySnapshot) => {

                this.snapshots = querySnapshot.docs;
                this.isLoadingShow = false;
            });

        },
        offSubscribe()
        {
            if(this.unsubscribe) this.unsubscribe();
        },
        async read(){

            const querySnapshot = await getDocs(collection(db, "articles"));
            this.snapshots = querySnapshot.docs;
        },
        async commentArticle(docID)
        {
           var commentsRef =  collection(db, 'articles', docID, 'comments');

           await addDoc(commentsRef,{
                id:  '사용자 아이디',
                body: '코멘트 내용입니다. 감사합니다. !!',
                regdate: serverTimestamp()
            });
 
        },
        deleteArticle(docID)
        {
            var ref = doc(db,'articles',docID);
            deleteDoc(ref);
            this.menu = false;
        }

  },
  mounted() {
    if(this.$store.state.isFirebaseAdmin)
    {
      //true
      this.isShow = true;
    }
    else{
      //false
      this.isShow = false;

    }
      this.subscribe();
  },
  destroyed() {
      this.offSubscribe();
  },
}
</script>

  