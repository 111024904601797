<template>
    <v-card >

     <v-subheader>Add Article</v-subheader>
     <v-card-text>
         <v-text-field 
            v-model="title" 
            label="Title(*)"
            required
            outlined/>

         <v-textarea 
            v-model="body" 
            label="Body(*)"
            required
            outlined/>

         <v-text-field 
            v-model="link" 
            label="Link Url(*)"
            required
            outlined/>

        <v-text-field 
            v-model="imgLink" 
            label="Image Url"
            required
            outlined/>

              <v-text-field 
            v-model="youtubeID" 
            label="youtube ID"
            required
            outlined/>

     </v-card-text>
     <v-card-actions>
         <v-btn @click="create">Create</v-btn>
     </v-card-actions>

    </v-card>
</template>

<script>

String.prototype.replaceAll = function(a, b) {
  let s = this
  for(;;) {
    let s2 = s.replace(a, b)
    if (s2 == s) {
      return s
    }
    s = s2
  }
}

import { collection, addDoc , serverTimestamp  } from "firebase/firestore"; 
import { db } from '@/plugins/firebase';


export default {
    data() {

        return {
            title:'',
            body:'',
            link:'',
            imgLink:'',
            youtubeID:''
        }
    },
   

    methods: {

        async create(){

            if( this.title =='' ||  this.body ==''||  this.link =='') return;


            var tempUrl = '';
            if(this.imgLink != '')
            {
               tempUrl = this.imgLink;
            }
            else if (this.youtubeID != '')
            {
                tempUrl = 'https://img.youtube.com/vi/'+ this.youtubeID +'/0.jpg';
            }
           
            await addDoc(collection(db, "articles"), 
            {
                title:  this.title,
                body: this.body.replaceAll('\n','<br>'),
                link: this.link,
                imgLink:tempUrl,
                regdate: serverTimestamp()
            });
 
        
            this.title = '';
            this.body = '';
            this.link = '';
            this.imgLink = '';
            this.youtubeID = '';
 
        }
        

  },
  mounted() {
      
  },
}
</script>

 