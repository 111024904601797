//export const INDUCTION_FEE = '0.1000 EOS'

// export const EDEN_CONTRACT_CODE ='genesis.eden'   //메인넷

export const EDEN_CONTRACT_CODE ='testedenkor1'   //메인넷
export const CHAIN_ID = 'aca376f206b8fc25a6ed44dbdc66547c36c6c33e3a119ffbeaef943642f0e906' //메인넷
//export const NODE_URL = 'https://eos.greymass.com' //메인넷
export const NODE_URL = 'https://eos.api.eosnation.io' //메인넷


// export const EDEN_CONTRACT_CODE ='hongtester22' 
// export const CHAIN_ID = '2a02a0053e5a8cf73a56ba0fda11e4d92e0238a4a2aa74fccf46d5a910746840' //정글넷
// export const NODE_URL = 'https://jungle3.greymass.com' //정글넷3


// export const EDEN_CONTRACT_CODE ='hongtester22' 
// export const CHAIN_ID = '73e4385a2708e6d7048834fbc1079f2fabb17b3c125b146af438971e90716c4d' //정글넷4
// export const NODE_URL = 'https://jungle4.cryptolions.io' //정글넷
 