<template>


   
   
    <v-avatar  v-if="!user" @click="googleSignIn" >
      <v-tooltip bottom >
        <template v-slot:activator="{ on, attrs }" >
          <v-icon v-bind="attrs" v-on="on"> mdi-account-circle-outline</v-icon>
        </template>
        <span>Login</span>
      </v-tooltip>
      
    </v-avatar>
   
    <v-menu v-else
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="200"
      offset-x
    >
      <template v-slot:activator="{ on, attrs }">

        <v-avatar size="30" v-bind="attrs" v-on="on" >
            <img
                :src=user.photoURL
                :alt=user.displayName>

        </v-avatar>
 
      </template>

      <v-card>
          
          <div style=" height: 50px;background-color:#DEE1E6">
          </div>
          <v-row justify="center">
              <v-avatar style="margin-top:-10px" color="white">
              <img class="pa-1"
                :src=user.photoURL
                :alt=user.displayName
              >
              </v-avatar>
          </v-row>

          <p style="text-align:center;height:5px;margin-top:15px">{{user.displayName}}</p>
          <p class="text-center font-weight-light">{{user.email}}</p>
 
          <br>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-chip
          color="primary"
          class="ma-2"
          @click="goProfile">

           <v-avatar left>
            <v-icon>mdi-account-circle</v-icon>
          </v-avatar>
          Profile
        </v-chip>
 
          <v-chip
            class="ma-2"
            @click="googleSignOut">

            <v-avatar left>
              <v-icon small>mdi-logout-variant</v-icon>
           </v-avatar>
            Log Out
          </v-chip>
          
        </v-card-actions>
      </v-card>
    </v-menu>
 
</template>

<script>

import { GoogleAuthProvider,signInWithPopup,signOut,onAuthStateChanged } from "firebase/auth";
import { auth } from '@/plugins/firebase';
import { doc, getDoc } from "firebase/firestore";
import { db } from '@/plugins/firebase';

const provider = new GoogleAuthProvider();

export default {
    data() {
        return {
            user:null,
            menu:false
        }
    },

    methods: {

        goProfile(){
           this.menu = false;
           this.$router.push('/profile') 
        },
        googleSignIn(){
          //로그인 요청시에 로그인 페이지로 이동한다.
          this.$router.push('/login') 
          //signInWithPopup(auth, provider);
        },
        googleSignOut(){
            signOut(auth);
            this.menu = false;
            this.$router.push('/login') 
        },
        init()
        {

          onAuthStateChanged(auth, async (user) =>  {

            this.user = user;
            this.$store.state.firebaseLoginUserInfo = user;

            if(user== null)
            {
              this.$store.state.isFirebaseAdmin = false;
            }
            else{
                
              const docRef = doc(db, "admins", user.auth.lastNotifiedUid);
              const docSnap = await getDoc(docRef);

          

              if (docSnap.exists()) {
                   
                this.$store.state.isFirebaseAdmin = true;
              } else {
                
                this.$store.state.isFirebaseAdmin = false;
              }
             
            }
              
          });
          
        }

  },
  mounted() {
      this.init();

      //TODO
      //1.로그인했지만 아직 users에 등록이 안되어 있으면 Profile 버튼을 안보여준다.
      //2.다른계정으로 로그인하기는?
  },
  
    
}

</script>

 