
<template>

  <v-main style="background-color:#F2F3F8">

      <v-container>

        <div v-if="false">
          <v-alert
                v-model="alertShow"
                color="red darken-1"
                
                dark
                icon="mdi-hammer-screwdriver"
                border="left"
                elevation="2"
                prominent>

                This page is scheduled for work. Currently, it is a prototype page.
          </v-alert>
 
        </div>

        <!-- TradingView Widget BEGIN -->
<div class="tradingview-widget-container" style="margin-bottom:5px">
  <div class="tradingview-widget-container__widget"></div>
  <script type="application/javascript" src="https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js" async>
  {
  "symbols": [
    {
      "description": "비트코인",
      "proName": "UPBIT:BTCKRW"
    },
    {
      "description": "이더리움",
      "proName": "UPBIT:ETHKRW"
    },
    {
      "description": "이오스",
      "proName": "UPBIT:EOSKRW"
    },
    {
      "description": "리플",
      "proName": "UPBIT:XRPKRW"
    },
    {
      "description": "폴리곤",
      "proName": "UPBIT:MATICKRW"
    },
    {
      "description": "도지",
      "proName": "UPBIT:DOGEKRW"
    }
  ],
  "showSymbolLogo": true,
  "colorTheme": "light",
  "isTransparent": false,
  "displayMode": "adaptive",
  "locale": "kr"
}
  </script>
</div>
<!-- TradingView Widget END -->

        <v-row >

          <!--메인:왼쪽 열  -->
          <v-col
            cols="12"
            sm="3">

              <v-card elevation="1" >


              <div class="tradingview-widget-container" style="margin:6px">
                <div class="tradingview-widget-container__widget"></div>
                <script type="application/javascript" src="https://s3.tradingview.com/external-embedding/embed-widget-mini-symbol-overview.js" async>
                {
                "symbol": "UPBIT:EOSKRW",
                "width": "100%",
                "height": "350",
                "locale": "kr",
                "dateRange": "1M",
                "colorTheme": "light",
                "trendLineColor": "rgba(0, 0, 255, 1)",
                "underLineColor": "rgba(41, 98, 255, 0.3)",
                "underLineBottomColor": "rgba(41, 98, 255, 0)",
                "isTransparent": true,
                "autosize": false,
                "largeChartUrl": "",
                "noTimeScale": false,
                "chartOnly": false
              }
                </script>
            </div>
            

              </v-card>


     

            <!-- <v-sheet 
             elevation="1"
              rounded="lg"
              style="padding:25px"
              min-height="300">
              
                 <v-row  >
 
                  <v-avatar
                      color="blue"
                      size="45"
                    >
                    <span class="white--text text-h7">Lv.23</span>
                  </v-avatar>
                    
                    <v-card
                      class="d-flex align-center "
                      flat
                      height="60"
                      tile>
                      <p style="margin-left:15px;font-size:20px">Jhon Doe</p>
                    </v-card>
                    
                 </v-row>

                <v-progress-linear
                style="margin:10px 10px 10px -10px;"
                      :value="levelPercent"
                      rounded
                      height="17"
                      
                    >
                      <strong style="font-size:12px;color:white">
                       {{ Math.ceil(levelPercent) }}%
                      </strong>
                </v-progress-linear>

                Collect exp to level up!
                <br><br>
                 -Complete the Quest to Earn Token<br>
                 -Create the Quest<br>
               
                <br>
                <h5 style="color:grey">don't have eos account? create or join eden</h5>
                  <v-btn
                    color="orange"
                    style="margin-left:-15px;"
                    text>
                        Create Account
                  </v-btn>
                  <v-btn
                    color="orange"
                    style="margin-left:-15px;"
                    text>
                        Join Eden
                  </v-btn>
                   
            </v-sheet> -->

             <!-- <v-chip class="ma-1"  v-for="(item, i) in menuItems"
                      :key="i">
                      {{item.text}}
                      
             </v-chip> -->

            <!-- <v-row  class="d-none d-sm-flex ml-1"> 
               

               <!- <v-col cols="12" sm="3">
                
               </v-col> -->
<!-- 
             <v-col cols="12" sm="9" >

                <v-list flat color="#F2F3F8">
                  <v-list-item-group v-model="selectedMenu"  color="primary">
                    <v-list-item
                      v-for="(item, i) in menuItems"
                      :key="i"
                    >
                      <v-list-item-icon>
                        <v-icon v-text="item.icon"></v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title v-text="item.text"></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>

              </v-col>

            </v-row> -->  


          </v-col>

           <!--메인:중앙 열  -->
          <v-col
            cols="12"
             style="margin-top:-17px"   
            sm="6">
 
            <Articles/>
     
            <!-- <v-card
              class="mx-auto"
              color="white"
              style="margin:17px 10px 10px 10px;"   
              
                v-for="n in 5"
                  :key="n"

            >
              <v-card-title>
                <v-icon
                  large
                  left
                >
                  mdi-twitter
                </v-icon>
                <span class="text-h5 font-weight-light">Twitter</span>
              </v-card-title>

              <v-card-text class="text-h6 font-weight-light">
                "Turns out semicolon-less style is easier and safer in TS because most gotcha edge cases are type invalid as well.urns out semicolon-less style is easier and safer in TS because most gotcha edge cases are type invalid as well.urns out semicolon-less style is easier and safer in TS because most gotcha edge cases are type invalid as well."
              </v-card-text>

              <v-card-actions>
                <v-list-item class="grow">
                  <v-list-item-avatar color="grey darken-3">
                    <v-img
                      class="elevation-6"
                      alt=""
                      src="https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light"
                    ></v-img>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title>Jhon Doe</v-list-item-title>
                  </v-list-item-content>

                  <v-row
                    align="center"
                    justify="end"
                  >
                    <v-icon class="mr-1">
                      mdi-heart
                    </v-icon>
                    <span class="subheading mr-2">256</span>
                    <span class="mr-1">·</span>
                    <v-icon class="mr-1">
                      mdi-share-variant
                    </v-icon>
                    <span class="subheading">45</span>
                  </v-row>
                </v-list-item>
              </v-card-actions>
            </v-card> -->
 
           
          </v-col>


          <!--메인:오른쪽 열  -->
          <v-col 
            cols="12"
            sm="3">

            <v-row >

            <v-col>

              <v-card elevation="1">


                <!-- TradingView Widget BEGIN -->
<div class="tradingview-widget-container">
  <div class="tradingview-widget-container__widget"  style="margin:10px"></div>
  <script type="application/javascript" src="https://s3.tradingview.com/external-embedding/embed-widget-market-overview.js" async>
  {
  "colorTheme": "light",
  "dateRange": "12M",
  "showChart": true,
  "locale": "kr",
  "largeChartUrl": "",
  "isTransparent": true,
  "showSymbolLogo": true,
  "showFloatingTooltip": false,
  "width": "100%",
  "height": "660",
  "plotLineColorGrowing": "rgba(41, 98, 255, 1)",
  "plotLineColorFalling": "rgba(41, 98, 255, 1)",
  "gridLineColor": "rgba(240, 243, 250, 0)",
  "scaleFontColor": "rgba(106, 109, 120, 1)",
  "belowLineFillColorGrowing": "rgba(41, 98, 255, 0.12)",
  "belowLineFillColorFalling": "rgba(41, 98, 255, 0.12)",
  "belowLineFillColorGrowingBottom": "rgba(41, 98, 255, 0)",
  "belowLineFillColorFallingBottom": "rgba(41, 98, 255, 0)",
  "symbolActiveColor": "rgba(41, 98, 255, 0.12)",
  "tabs": [
    {
      "title": "지수",
      "symbols": [
        {
          "s": "FOREXCOM:SPXUSD",
          "d": "S&P 500"
        },
        {
          "s": "FOREXCOM:NSXUSD",
          "d": "US 100"
        },
        {
          "s": "FOREXCOM:DJI",
          "d": "Dow 30"
        },
        {
          "s": "INDEX:NKY",
          "d": "Nikkei 225"
        },
        {
          "s": "INDEX:DEU40",
          "d": "DAX Index"
        },
        {
          "s": "BITFINEX:BTCUSD",
          "d": "BTC"
        },
        {
          "s": "UPBIT:EOSKRW",
          "d": "EOS"
        }
      ],
      "originalTitle": "Indices"
    },
    {
      "title": "선물",
      "symbols": [
        {
          "s": "CME_MINI:ES1!",
          "d": "S&P 500"
        },
        {
          "s": "CME:6E1!",
          "d": "Euro"
        },
        {
          "s": "COMEX:GC1!",
          "d": "Gold"
        },
        {
          "s": "NYMEX:CL1!",
          "d": "Crude Oil"
        },
        {
          "s": "NYMEX:NG1!",
          "d": "Natural Gas"
        },
        {
          "s": "CBOT:ZC1!",
          "d": "Corn"
        }
      ],
      "originalTitle": "Futures"
    },
    {
      "title": "채권",
      "symbols": [
        {
          "s": "CME:GE1!",
          "d": "Eurodollar"
        },
        {
          "s": "CBOT:ZB1!",
          "d": "T-Bond"
        },
        {
          "s": "CBOT:UB1!",
          "d": "Ultra T-Bond"
        },
        {
          "s": "EUREX:FGBL1!",
          "d": "Euro Bund"
        },
        {
          "s": "EUREX:FBTP1!",
          "d": "Euro BTP"
        },
        {
          "s": "EUREX:FGBM1!",
          "d": "Euro BOBL"
        }
      ],
      "originalTitle": "Bonds"
    },
    {
      "title": "외환",
      "symbols": [
        {
          "s": "FX:EURUSD",
          "d": "EUR/USD"
        },
        {
          "s": "FX:GBPUSD",
          "d": "GBP/USD"
        },
        {
          "s": "FX:USDJPY",
          "d": "USD/JPY"
        },
        {
          "s": "FX:USDCHF",
          "d": "USD/CHF"
        },
        {
          "s": "FX:AUDUSD",
          "d": "AUD/USD"
        },
        {
          "s": "FX:USDCAD",
          "d": "USD/CAD"
        }
      ],
      "originalTitle": "Forex"
    }
  ]
}
  </script>
</div>
<!-- TradingView Widget END -->



              </v-card>
    
              </v-col>
             
            </v-row>

          
             <v-row>

              <v-col>

               <AddArticle v-show="isShow"/>

              </v-col>
        
            </v-row>


          </v-col>


        </v-row>

      </v-container>


    </v-main>

</template>

<script>


import Articles from '@/components/Articles'
import AddArticle from '@/components/AddArticle'

export default {
  
  components:{
    Articles,AddArticle
  },
  
  data() {
    return {
      
 labels: [
        '1.Dec',
        '2.Dec',
        '3.Dec',
        '4.Dec',
        '5.Dec',
        '6.Dec',
        '7.Dec',
        
      ],
      value: [
        500,
        425,
        510,
        490,
        310,
        460,
        850,
      ],
      levelPercent:73,
      alertShow:true,
      isShow:false,
      menuItems: [
        {
          icon: 'mdi-folder-star-multiple',
          text: 'New',
        },
        {
          icon: 'mdi-star',
          text: 'ALL',
        },
        {
          icon: 'mdi-shield-star',
          text: 'Xangle',
        },
        {
          icon: 'mdi-shield-star',
          text: '#RANT',
        },
        {
          icon: 'mdi-police-badge',
          text: 'ENF',
        },
         {
          icon: 'mdi-information',
          text: 'Read Basic Info About EOS',
        },
        {
          icon: 'mdi-ticket-confirmation',
          text: 'Etc',
        },
        
      ],
       selectedMenu: 0,
    }
  },
  watch: {

  '$store.state.isFirebaseAdmin': function() {

    if(this.$store.state.isFirebaseAdmin)
    {
      //true
      this.isShow = true;
    }
    else{
      //false
      this.isShow = false;

    }

   }
  },
 mounted() {
   if(this.$store.state.isFirebaseAdmin)
    {
      //true
      this.isShow = true;
    }
    else{
      //false
      this.isShow = false;

    }
     this.$vuetify.goTo(0)
  },


}
</script>

