import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);

export default new Vuetify({

    theme: {
        themes: {
          light: {
            primary: '#4361EE',
            secondary: '#805DCA',
            anchor: '#8c9eff',
          },
        },
      },

});
