import { EDEN_CONTRACT_CODE } from "../config.js";

export default {

    install(Vue) {

        Vue.prototype.$edenTable_elect_curr = async function()
        {
          var sendJson = {
            code: EDEN_CONTRACT_CODE,
            table: "elect.curr",
            scope: "0",
            limit: "1"
            }

            var result =  await this.$eosjsRPC.get_table_rows(sendJson)
            return result.rows[0]
       }
       Vue.prototype.$edenTable_elect_state = async function()
       {

          var sendJson = {
              code: EDEN_CONTRACT_CODE,
              table: "elect.state",
              scope: "0",
              limit: "1"
          };

          var result =  await this.$eosjsRPC.get_table_rows(sendJson)
          return result.rows[0][1].last_election_time
       }
       Vue.prototype.$edenTable_votes = async function()
       {
         var sendJson = {
           code: EDEN_CONTRACT_CODE,
           table: "votes",
           scope: "0",
           limit: "100"
           }
           var result =  await this.$eosjsRPC.get_table_rows(sendJson)
           return result.rows
      }
      Vue.prototype.$edenTable_member = async function()
       {
         var sendJson = {
           code: EDEN_CONTRACT_CODE,
           table: "member",
           scope: "0",
           limit: "100"
           }
           var result =  await this.$eosjsRPC.get_table_rows(sendJson)
           return result.rows
        }
        Vue.prototype.$edenTable_account = async function()
        {
          var sendJson = {
            code: EDEN_CONTRACT_CODE,
            table: "account",
            scope: "0",
            limit: "100"
            }
            var result =  await this.$eosjsRPC.get_table_rows(sendJson)
            return result.rows
      }
      Vue.prototype.$edenTable_distaccount = async function()
      {
        var sendJson = {
          code: EDEN_CONTRACT_CODE,
          table: "distaccount",
          scope: "0",
          limit: "100"
          }
          var result =  await this.$eosjsRPC.get_table_rows(sendJson)
          return result.rows
      }
      Vue.prototype.$edenTable_memberstats = async function()
      {
        var sendJson = {
          code: EDEN_CONTRACT_CODE,
          table: "memberstats",
          scope: "0",
          limit: "1"
          }
          var result =  await this.$eosjsRPC.get_table_rows(sendJson)
          return result.rows[0][1].ranks
      }
      Vue.prototype.$edenTable_distribution = async function()
     {
       var sendJson = {
         code: EDEN_CONTRACT_CODE,
         table: "distribution",
         scope: "0",
         limit: "100"
         }
         var result =  await this.$eosjsRPC.get_table_rows(sendJson)
         return result.rows
    }
      

    }

}


