var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{attrs:{"id":"inspire"}},[_c('v-app-bar',{attrs:{"app":"","color":"white","elevation":"1"}},[_c('v-img',{attrs:{"max-height":"32","max-width":"22","src":require('@/assets/questico.png')}}),_c('v-app-bar-title',{staticClass:"d-none d-sm-flex ml-1"},[_c('strong',[_vm._v("QUEST")])]),_c('v-tabs',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isMobile),expression:"!isMobile"}],staticClass:"sm-n9",attrs:{"centered":"","color":"grey darken-1"}},[_c('v-tab',{on:{"click":_vm.routerlinkHome}},[_vm._v("Home")]),_c('v-tab',{on:{"click":_vm.routerlinkTokenSend}},[_vm._v("Token")]),_c('v-tab',{on:{"click":_vm.routerlinkDaily}},[_vm._v(" Daily ")]),_c('v-tab',{on:{"click":_vm.routerlinkCalendar}},[_c('v-badge',{attrs:{"bordered":"","color":"orange","dot":""}},[_vm._v(" Calendar ")])],1),_c('v-tab',{on:{"click":_vm.routerlinkQuest}},[_vm._v(" QUEST ")]),_c('v-tab',{on:{"click":_vm.routerlinkDapp}},[_vm._v("Dapp")]),_c('v-tab',{on:{"click":_vm.routerlinkAbout}},[_vm._v("About")])],1),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(!_vm.isLogined),expression:"!isLogined"}],attrs:{"icon":""},on:{"click":_vm.loginAnchor}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-fingerprint ")])],1)]}}])},[_c('span',[_vm._v("Login With Anchor")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.isLogined),expression:"isLogined"}],attrs:{"icon":""},on:{"click":_vm.logoutAnchor}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-fingerprint-off ")])],1)]}}])},[_c('span',[_vm._v("Logout")])]),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLogined),expression:"isLogined"}],staticStyle:{"text-transform":"none"},attrs:{"text":""}},[_c('h3',[_vm._v(_vm._s(_vm.loginAccountName)+" ")])]),_c('AuthBtn'),_c('v-menu',{attrs:{"left":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-menu-open")])],1)]}}])},[_c('v-list',[_c('v-list-item',[_c('v-list-item-title',{on:{"click":_vm.routerlinkHome}},[_vm._v("Home")])],1),_c('v-list-item',[_c('v-list-item-title',{on:{"click":_vm.routerlinkTokenSend}},[_vm._v("Token")])],1),_c('v-list-item',[_c('v-list-item-title',{on:{"click":_vm.routerlinkDaily}},[_vm._v("Daily")])],1),_c('v-list-item',[_c('v-list-item-title',{on:{"click":_vm.routerlinkCalendar}},[_vm._v("Calendar")])],1),_c('v-list-item',[_c('v-list-item-title',{on:{"click":_vm.routerlinkQuest}},[_vm._v("Quest")])],1),_c('v-list-item',[_c('v-list-item-title',{on:{"click":_vm.routerlinkDapp}},[_vm._v("dApp")])],1),_c('v-list-item',[_c('v-list-item-title',{on:{"click":_vm.routerlinkAbout}},[_vm._v("About")])],1)],1)],1)],1),_c('router-view'),[_c('v-footer',{attrs:{"padless":""}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_vm._v(" Copyright © "),_c('strong',[_vm._v("EOSQuest")]),_vm._v(" "+_vm._s(new Date().getFullYear())+"."),(_vm.sm)?_c('br'):_vm._e(),_c('v-chip',{staticClass:"ml-1",attrs:{"small":""},on:{"click":_vm.goTerm}},[_vm._v("Terms of Use")]),_vm._v(" "),_c('v-chip',{attrs:{"small":""},on:{"click":_vm.goPrivacy}},[_vm._v("Privacy")])],1)],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }