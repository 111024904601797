import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './plugins/firebase'
import '@babel/polyfill'
import edenFunctions from './commonFunctions/edenFunction.js'
import edenViewTable from './commonFunctions/edenViewTable.js'
import tokenImages from './commonFunctions/tokenImages'


const { JsonRpc } = require('eosjs');

const rpc = new JsonRpc('https://eos.greymass.com', { fetch });
//const rpc = new JsonRpc('https://jungle3.cryptolions.io:443', { fetch }); //정글넷3
// const rpc = new JsonRpc('https://jungle4.cryptolions.io:443', { fetch }); //정글넷4

Vue.prototype.$eosjsRPC = rpc

Vue.prototype.$IsTEST = false

Vue.config.productionTip = false
Vue.use(edenFunctions)
Vue.use(edenViewTable)
Vue.use(tokenImages)

new Vue({
  router,
  store,
  vuetify,
  render: function (h) { return h(App) }
}).$mount('#app')
