export default {

    install(Vue) {

        Vue.prototype.$getTokenImageUrl = function(tokenContractName,tokenName)
        {
           switch (tokenContractName) {
            case 'everipediaiq':
                return 'https://www.api.bloks.io/image-proxy/display?w=100&h=100&url=https://raw.githubusercontent.com/BlockABC/eos-tokens/master/tokens/everipediaiq/IQ.png&op=resize';
            case 'thepeostoken':
                return 'https://www.api.bloks.io/image-proxy/display?w=100&h=100&url=https://raw.githubusercontent.com/BlockABC/eos-tokens/master/tokens/thepeostoken/PEOS.png&op=resize';
            case 'pizzatotoken':
                return 'https://www.api.bloks.io/image-proxy/display?w=100&h=100&url=https://raw.githubusercontent.com/BlockABC/eos-tokens/master/tokens/pizzatotoken/PIZZA.png&op=resize';
            case 'hirevibeshvt':
                return 'https://www.api.bloks.io/image-proxy/display?w=100&h=100&url=https://raw.githubusercontent.com/BlockABC/eos-tokens/master/tokens/hirevibeshvt/HVT.png&op=resize';
            case 'token.defi':
                return 'https://www.api.bloks.io/image-proxy/display?w=100&h=100&url=https://raw.githubusercontent.com/eoscafe/eos-airdrops/master/logos/token.defi-box.png&op=resize';
            case 'chexchexchex':
                return 'https://www.api.bloks.io/image-proxy/display?w=100&h=100&url=https://raw.githubusercontent.com/BlockABC/eos-tokens/master/tokens/chexchexchex/CHEX.png&op=resize';
            case 'emanateoneos':
                return 'https://www.api.bloks.io/image-proxy/display?w=100&h=100&url=https://raw.githubusercontent.com/eoscafe/eos-airdrops/master/logos/emanate.png&op=resize';
            case 'eosdtsttoken':
                return 'https://www.api.bloks.io/image-proxy/display?w=100&h=100&url=https://raw.githubusercontent.com/BlockABC/eos-tokens/master/tokens/eosdtsttoken/EOSDT.png&op=resize';
            case 'eosiotptoken':
                return 'https://www.api.bloks.io/image-proxy/display?w=100&h=100&url=https://raw.githubusercontent.com/BlockABC/eos-tokens/master/tokens/eosiotptoken/TPT.png&op=resize';
            case 'eossanguotkt':
                return 'https://www.api.bloks.io/image-proxy/display?w=100&h=100&url=https://raw.githubusercontent.com/BlockABC/eos-tokens/master/tokens/eossanguotkt/TKT.png&op=resize';
            case 'tethertether':
                return 'https://www.api.bloks.io/image-proxy/display?w=100&h=100&url=https://raw.githubusercontent.com/eoscafe/eos-airdrops/master/logos/USDT.png&op=resize';
            case 'krowndactokn':
                return 'https://www.api.bloks.io/image-proxy/display?w=100&h=100&url=https://raw.githubusercontent.com/eoscafe/eos-airdrops/master/logos/KROWN.png&op=resize';
            case 'vig111111111':
                return 'https://www.api.bloks.io/image-proxy/display?w=100&h=100&url=https://raw.githubusercontent.com/eoscafe/eos-airdrops/master/logos/VIG.png&op=resize';
            case 'svxmintofeos':
                return 'https://www.api.bloks.io/image-proxy/display?w=100&h=100&url=https://raw.githubusercontent.com/BlockABC/eos-tokens/master/tokens/svxmintofeos/SVX.png&op=resize';
            case 'accissuer123':
                return 'https://www.api.bloks.io/image-proxy/display?w=100&h=100&url=https://raw.githubusercontent.com/BlockABC/eos-tokens/master/tokens/accissuer123/ACC.png&op=resize';
            case 'admsadmtoken':
                return 'https://www.api.bloks.io/image-proxy/display?w=100&h=100&url=https://raw.githubusercontent.com/BlockABC/eos-tokens/master/tokens/admsadmtoken/ADM.png&op=resize';
            case 'anoxanoxanox':
                return 'https://www.api.bloks.io/image-proxy/display?w=100&h=100&url=https://raw.githubusercontent.com/eoscafe/eos-airdrops/master/logos/anx-sm.png&op=resize';
            case 'athenastoken':
                return 'https://www.api.bloks.io/image-proxy/display?w=100&h=100&url=https://raw.githubusercontent.com/BlockABC/eos-tokens/master/tokens/athenastoken/ATHENA.png&op=resize';
            case 'bet24tokens1':
                return 'https://www.api.bloks.io/image-proxy/display?w=100&h=100&url=https://raw.githubusercontent.com/BlockABC/eos-tokens/master/tokens/bet24tokens1/CHIP.png&op=resize';
            case 'betdividends':
                return 'https://www.api.bloks.io/image-proxy/display?w=100&h=100&url=https://raw.githubusercontent.com/BlockABC/eos-tokens/master/tokens/betdividends/BET.png&op=resize';
            case 'betkingtoken':
                return 'https://www.api.bloks.io/image-proxy/display?w=100&h=100&url=https://raw.githubusercontent.com/eoscafe/eos-airdrops/master/logos/betking.png&op=resize';
            case 'bgbgbgbgbgbg':
                return 'https://www.api.bloks.io/image-proxy/display?w=100&h=100&url=https://raw.githubusercontent.com/BlockABC/eos-tokens/master/tokens/bgbgbgbgbgbg/BG.png&op=resize';
            case 'billionairet':
                return 'https://www.api.bloks.io/image-proxy/display?w=100&h=100&url=https://raw.githubusercontent.com/eoscafe/eos-airdrops/master/logos/billionaire.png&op=resize';
            case 'bingobetoken':
                return 'https://www.api.bloks.io/image-proxy/display?w=100&h=100&url=https://raw.githubusercontent.com/BlockABC/eos-tokens/master/tokens/bingobetoken/BINGO.png&op=resize';
            case 'bitpietokens':
                return 'https://www.api.bloks.io/image-proxy/display?w=100&h=100&url=https://raw.githubusercontent.com/BlockABC/eos-tokens/master/tokens/bitpietokens/EBTC.png&op=resize';
            case 'boidcomtoken':
                return 'https://www.api.bloks.io/image-proxy/display?w=100&h=100&url=https://raw.githubusercontent.com/BlockABC/eos-tokens/master/tokens/boidcomtoken/BOID.png&op=resize';
            case 'buildertoken':

                if ( tokenName == 'EOSISH')
                {
                    return 'https://www.api.bloks.io/image-proxy/display?w=100&h=100&url=https://raw.githubusercontent.com/BlockABC/eos-tokens/master/tokens/buildertoken/EOSISH.png&op=resize'
                }
                else{
                    return 'https://www.api.bloks.io/image-proxy/display?w=100&h=100&url=https://raw.githubusercontent.com/eoscafe/eos-airdrops/master/logos/placeholder.png&op=resize'                    
                }
            case 'd.mail':
                return 'https://www.api.bloks.io/image-proxy/display?w=100&h=100&url=https://raw.githubusercontent.com/BlockABC/eos-tokens/master/tokens/d.mail/MAIL.png&op=resize';
            case 'danchortoken':
                return 'https://www.api.bloks.io/image-proxy/display?w=100&h=100&url=https://raw.githubusercontent.com/eoscafe/eos-airdrops/master/logos/usn.png&op=resize';
            case 'dappservices':
                return 'https://www.api.bloks.io/image-proxy/display?w=100&h=100&url=https://i.imgur.com/ljSkkNB.png&op=resize';
            case 'eosadddddddd':
                return 'https://www.api.bloks.io/image-proxy/display?w=100&h=100&url=https://raw.githubusercontent.com/BlockABC/eos-tokens/master/tokens/eosadddddddd/ADD.png&op=resize';
            case 'eosblackteam':
                return 'https://www.api.bloks.io/image-proxy/display?w=100&h=100&url=https://raw.githubusercontent.com/BlockABC/eos-tokens/master/tokens/eosblackteam/BLACK.png&op=resize';
            case 'eosdactokens':
                return 'https://www.api.bloks.io/image-proxy/display?w=100&h=100&url=https://raw.githubusercontent.com/BlockABC/eos-tokens/master/tokens/eosdactokens/EOSDAC.png&op=resize';
            case 'eosiochaince':
                return 'https://www.api.bloks.io/image-proxy/display?w=100&h=100&url=https://raw.githubusercontent.com/BlockABC/eos-tokens/master/tokens/eosiochaince/CET.png&op=resize';
            case 'eosiomeetone':
                return 'https://www.api.bloks.io/image-proxy/display?w=100&h=100&url=https://raw.githubusercontent.com/BlockABC/eos-tokens/master/tokens/eosiomeetone/MEETONE.png&op=resize';
            case 'eoxeoxeoxeox':
                return 'https://www.api.bloks.io/image-proxy/display?w=100&h=100&url=https://raw.githubusercontent.com/BlockABC/eos-tokens/master/tokens/eoxeoxeoxeox/EOX.png&op=resize';
            case 'ethsidechain':
                return 'https://www.api.bloks.io/image-proxy/display?w=100&h=100&url=https://raw.githubusercontent.com/BlockABC/eos-tokens/master/tokens/ethsidechain/EETH.png&op=resize';
            case 'horustokenio':
                return 'https://www.api.bloks.io/image-proxy/display?w=100&h=100&url=https://raw.githubusercontent.com/BlockABC/eos-tokens/master/tokens/horustokenio/HORUS.png&op=resize';
            case 'newdexissuer':
                return 'https://www.api.bloks.io/image-proxy/display?w=100&h=100&url=https://raw.githubusercontent.com/BlockABC/eos-tokens/master/tokens/newdexissuer/NDX.png&op=resize';
            case 'octtothemoon':
                return 'https://www.api.bloks.io/image-proxy/display?w=100&h=100&url=https://raw.githubusercontent.com/BlockABC/eos-tokens/master/tokens/octtothemoon/OCT.png&op=resize';
            case 'okkkkkkkkkkk':

                if ( tokenName == 'LED')
                {
                    return 'https://www.api.bloks.io/image-proxy/display?w=100&h=100&url=https://raw.githubusercontent.com/BlockABC/eos-tokens/master/tokens/okkkkkkkkkkk/LED.jpg&op=resize';
                }
                else{
                    return 'https://www.api.bloks.io/image-proxy/display?w=100&h=100&url=https://raw.githubusercontent.com/eoscafe/eos-airdrops/master/logos/placeholder.png&op=resize'                    
                }
                
            case 'ploutoztoken':
                return 'https://www.api.bloks.io/image-proxy/display?w=100&h=100&url=https://raw.githubusercontent.com/BlockABC/eos-tokens/master/tokens/ploutoztoken/PLO.png&op=resize';
            case 'poormantoken':
                return 'https://www.api.bloks.io/image-proxy/display?w=100&h=100&url=https://raw.githubusercontent.com/BlockABC/eos-tokens/master/tokens/poormantoken/POOR.png&op=resize';
            case 'pumlhealthio':
                return 'https://www.api.bloks.io/image-proxy/display?w=100&h=100&url=https://raw.githubusercontent.com/BlockABC/eos-tokens/master/tokens/pumlhealthio/PUML.png&op=resize';
            case 'sensegenesis':
                return 'https://www.api.bloks.io/image-proxy/display?w=100&h=100&url=https://raw.githubusercontent.com/BlockABC/eos-tokens/master/tokens/sensegenesis/SENSE.png&op=resize';
            case 'taketooktook':
                return 'https://www.api.bloks.io/image-proxy/display?w=100&h=100&url=https://raw.githubusercontent.com/BlockABC/eos-tokens/master/tokens/taketooktook/TOOK.jpg&op=resize';
            case 'thebeantoken':
                return 'https://www.api.bloks.io/image-proxy/display?w=100&h=100&url=https://raw.githubusercontent.com/BlockABC/eos-tokens/master/tokens/thebeantoken/BEAN.png&op=resize';
            case 'therealkarma':
                return 'https://www.api.bloks.io/image-proxy/display?w=100&h=100&url=https://raw.githubusercontent.com/BlockABC/eos-tokens/master/tokens/therealkarma/KARMA.png&op=resize';
            case 'token.newdex':
                return 'https://www.api.bloks.io/image-proxy/display?w=100&h=100&url=https://raw.githubusercontent.com/eoscafe/eos-airdrops/master/logos/ndx.png&op=resize';
            case 'vigortoken11':
                return 'https://www.api.bloks.io/image-proxy/display?w=100&h=100&url=https://raw.githubusercontent.com/eoscafe/eos-airdrops/master/logos/VIGOR.png&op=resize';
            case 'weedcashntwk':
                return 'https://www.api.bloks.io/image-proxy/display?w=100&h=100&url=https://raw.githubusercontent.com/eoscafe/eos-airdrops/master/logos/weedcashnew1SteemEOSlogos1png.png&op=resize';
            case 'zkstokensr4u':
                return 'https://www.api.bloks.io/image-proxy/display?w=100&h=100&url=https://raw.githubusercontent.com/BlockABC/eos-tokens/master/tokens/zkstokensr4u/ZKS.png&op=resize';
            case 'stoken.defi':
                return 'https://www.api.bloks.io/image-proxy/display?w=100&h=100&url=https://raw.githubusercontent.com/eoscafe/eos-airdrops/master/logos/stoken.defi-seos.png&op=resize';  
            case 'lptoken.defi':
                return 'https://www.api.bloks.io/image-proxy/display?w=100&h=100&url=https://raw.githubusercontent.com/eoscafe/eos-airdrops/master/logos/lptoken-boxgl.png&op=resize';
            case 'eth.ptokens':
                return 'https://www.api.bloks.io/image-proxy/display?w=100&h=100&url=https://raw.githubusercontent.com/eoscafe/eos-airdrops/master/logos/peth.png&op=resize';
            case 'capitaltatch':
                return 'https://www.api.bloks.io/image-proxy/display?w=100&h=100&url=https://raw.githubusercontent.com/eoscafe/eos-airdrops/master/logos/TCN-Tatch-transp.png&op=resize'
            case 'cet.f':
                if ( tokenName == 'CETF')
                {
                    return 'https://www.api.bloks.io/image-proxy/display?w=100&h=100&url=https://raw.githubusercontent.com/eoscafe/eos-airdrops/master/logos/CETF.png&op=resize'
                }
                else if( tokenName == 'EOSETF') {
                    return 'https://www.api.bloks.io/image-proxy/display?w=100&h=100&url=https://raw.githubusercontent.com/eoscafe/eos-airdrops/master/logos/EOSETF.png&op=resize'
                }
                else{
                    return 'https://www.api.bloks.io/image-proxy/display?w=100&h=100&url=https://raw.githubusercontent.com/eoscafe/eos-airdrops/master/logos/placeholder.png&op=resize'
                }
                    
            case 'consortiumlv':
                return 'https://www.api.bloks.io/image-proxy/display?w=100&h=100&url=https://raw.githubusercontent.com/eoscafe/eos-airdrops/master/logos/consortium.png&op=resize'
            case 'eosluckchain':
                return 'https://www.api.bloks.io/image-proxy/display?w=100&h=100&url=https://raw.githubusercontent.com/BlockABC/eos-tokens/master/tokens/eosluckchain/LUCK.png&op=resize'
            case 'minedfstoken':
                return 'https://www.api.bloks.io/image-proxy/display?w=100&h=100&url=https://raw.githubusercontent.com/eoscafe/eos-airdrops/master/logos/minedfstoken-dfs.png&op=resize'
            case 'effecttokens':
                return 'https://www.api.bloks.io/image-proxy/display?w=100&h=100&url=https://raw.githubusercontent.com/eoscafe/eos-airdrops/master/logos/efx.png&op=resize'
            case 'dadtoken1111':
                return 'https://www.api.bloks.io/image-proxy/display?w=100&h=100&url=https://raw.githubusercontent.com/eoscafe/eos-airdrops/master/logos/dad-logo.png&op=resize' 
            case 'eoseventoken':
                return 'https://www.api.bloks.io/image-proxy/display?w=100&h=100&url=https://raw.githubusercontent.com/BlockABC/eos-tokens/master/tokens/eoseventoken/SVN.png&op=resize'
            case 'eosnowbpower':
                return 'https://www.api.bloks.io/image-proxy/display?w=100&h=100&url=https://raw.githubusercontent.com/eoscafe/eos-airdrops/master/logos/eosnow.png&op=resize'
            case 'fight22death':
                return 'https://www.api.bloks.io/image-proxy/display?w=100&h=100&url=https://raw.githubusercontent.com/BlockABC/eos-tokens/master/tokens/fight22death/NFT.png&op=resize'                   
            case 'infinicoinio':
                return 'https://www.api.bloks.io/image-proxy/display?w=100&h=100&url=https://raw.githubusercontent.com/BlockABC/eos-tokens/master/tokens/infinicoinio/INF.png&op=resize' 
            case 'lumetokenctr':
                return 'https://www.api.bloks.io/image-proxy/display?w=100&h=100&url=https://raw.githubusercontent.com/BlockABC/eos-tokens/master/tokens/lumetokenctr/LUME.png&op=resize' 
            case 'onessusblock':
                return 'https://www.api.bloks.io/image-proxy/display?w=100&h=100&url=https://raw.githubusercontent.com/eoscafe/eos-airdrops/master/logos/VOID.png&op=resize'                   
            case 'openbrmeos11':
                return 'https://www.api.bloks.io/image-proxy/display?w=100&h=100&url=https://raw.githubusercontent.com/BlockABC/eos-tokens/master/tokens/openbrmeos11/BRM.png&op=resize' 
            case 'ridlridlcoin':
                return 'https://www.api.bloks.io/image-proxy/display?w=100&h=100&url=https://raw.githubusercontent.com/BlockABC/eos-tokens/master/tokens/ridlridlcoin/RIDL.png&op=resize' 
            default:
                return 'https://www.api.bloks.io/image-proxy/display?w=100&h=100&url=https://raw.githubusercontent.com/eoscafe/eos-airdrops/master/logos/placeholder.png&op=resize'
           }
        }

    }
}