import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/tokensend',
    name: 'TokenSend',
    component: () => import('../views/TokenSend.vue'),
  },
  {
    path: '/Dapp',
    name: 'Dapp',
    component: () => import('../views/Dapp.vue'),
  },
  {
    path: '/Quest',
    name: 'Quest',
    component: () => import('../views/Quest.vue'),
  },
  {
    path: '/quest/:QuestID',
    name: 'QuestDetail',
    component: () => import('../views/QuestDetail.vue'),
  },
  {
    path: '/Profile',
    name: 'Profile',
    component: () => import('../views/Profile.vue'),
  },
  {
    path: '/Privacy',
    name: 'Privacy',
    component: () => import('../views/Pages/Privacy.vue'),
  },
  {
    path: '/Terms',
    name: 'Terms',
    component: () => import('../views/Pages/Terms.vue'),
  },
  {
    path: '/Bakje',
    name: 'Bakje',
    component: () => import('../views/Bakje.vue'),
  },
  {
    path: '/Daily',
    name: 'Daily',
    component: () => import('../views/Daily.vue'),
  },
  {
    path: '/Calendar',
    name: 'Calendar',
    component: () => import('../views/Calendar.vue'),
  },
  {
    path: '/Login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/About.vue')
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
