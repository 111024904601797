import Vue from 'vue';
import { initializeApp } from 'firebase/app';
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import firebaseConfig from '../../firebaseConfig';
 
const firebaseApp = initializeApp(firebaseConfig);

export const auth = getAuth();
auth.languageCode = 'ko';

export const db = getFirestore();

export const storage = getStorage();




// import { collection, addDoc } from "firebase/firestore"; 


// addDoc(collection(db, "test"), {
//   first: "Ada",
//   last: "Lovelace",
//   born: 1815
// })
// .then(r=>console.log(r))
// .catch(e=>console.error(e))
